// Bootstrap
@import '../bootstrap/css/bootstrap.css';
// Variables
@import 'variables';

// Filepond Styles
// @import '../css/filepond.css';
@import 'https://unpkg.com/filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
@import 'https://unpkg.com/filepond/dist/filepond.min.css';

// App Styles
@import '../css/app.css';

// @import '../css/styles-old.css';

@import '../css/styles.css';

@import '../css/custom.css';

// Fonts
@import '../fonts/akrobat-all';

// Font Awesome
@import '../fonts/fontawesome-all';