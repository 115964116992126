
/*** FILE: custom.css ***/
/*** UPDATED: FEB 16, 2024 ***/
  
.text-shadow {
  text-shadow: 1px 1px 4px black;
}

.text-xs {
  font-size: .7rem;
}

.text-color1 {
  color: var(--color-1);
}
.text-color2 {
  color: var(--color-2);
}
.text-color3 {
  color: var(--color-3);
}
.text-color4 {
  color: var(--color-4);
}
.text-color5 {
  color: var(--color-5);
}
.text-color6 {
  color: var(--color-6);
}
.text-color7 {
  color: var(--color-7);
}

.bg-green {
  background-color: var(--color-1) !important;
}

.bg-dark-green {
  background-color: var(--color-2) !important;
}

.btn.btn-dark-green,
.btn.btn-dark-green:hover  {
  background-color: var(--color-2);
  color: var(--bs-light);
  border-color: var(--color-2);
  opacity: .9;
}

.btn.btn-dark-green:hover {
  opacity: 1;
}

.btn.btn-green,
.btn.btn-green:hover  {
  background-color: var(--color-1);
  color: var(--bs-light);
  border-color: var(--color-1);
  opacity: .9;
}

.btn.btn-green:hover {
  opacity: 1;
}

.btn.btn-dark-link,
.btn.btn-dark-link:hover {
  color: var(--color-2);
  opacity: .9;
}

.btn.btn-dark-link:hover {
  opacity: 1;
}

.btn.btn-link,
.btn.btn-link:hover {
  color: var(--bs-light);
  opacity: .9;
}

.btn.btn-link:hover {
  opacity: 1;
}
  
.btn.btn-blue-dark {
  color: #fff;
  background-color: #2A2F84;
  border: 1px solid #2A2F84;
  opacity: .9;
}

.btn.btn-blue-dark:hover, .btn.btn-blue-dark:focus {
  color: #fff;
  background-color: #9381ff;
  border: 1px solid #9381ff;
  opacity: 1;
}

.text-blue-light {
  color: #9381ff;
}

.btn.btn-cta, .nav-link.btn.btn-cta, .btn.btn-group.btn-cta {
  border-radius: 25px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  text-transform: capitalize;
  align-items: center;
}
  
  @media (max-width: 560px) {
    .btn-group.btn-cta .btn:first-child {
      font-size: 14px;
    }
  }
  
  .btn-group.btn-cta .dropdown-menu {
    padding: 10px 5px;
  }
  
  .btn-group.btn-cta .dropdown-menu, .btn-group.btn-cta .dropdown-item {
    border-radius: 25px;
  }
  
  .btn.btn-cta:hover, .nav-link.btn.btn-cta:hover, .btn-group.btn-cta:hover {
    border-radius: 25px;
  }
  
  .header-home-title, .header-home-subtitle, .header-page-title, .header-page-subtitle {
    color: var(--color-10);
  }
  
  .concept-content-title, .default-content-title, .concept-content-summary, .default-content-summary {
    color: var(--color-21);
  }
  
  /* ** Background Colors ** */
  
  .color1-bg {
    background-color: var(--color-1) !important;
  }
  
  .color2-bg {
    background-color: var(--color-2) !important;
  }
  
  .color3-bg {
    background-color: var(--color-3) !important;
  }
  
  .color4-bg {
    background-color: var(--color-4) !important;
  }
  
  .color5-bg {
    background-color: var(--color-5) !important;
  }
  
  .color6-bg {
    background-color: var(--color-6) !important;
  }
  
  .white-color2-bg {
    background-color: var(--bs-light) !important;
  }
  
  /* ** Text Colors ** */
  
  .color1-bg .header-home-title, .color1-bg .header-home-subtitle, .color1-bg .header-page-title, .color1-bg .header-page-subtitle,  .color1-bg .concept-content-title, .color1-bg .default-content-title {
    color: var(--color-10);
  }
  
  .color1-bg .concept-content-summary, .color1-bg .default-content-summary {
    color: var(--color-10);
  }
  
  .color1-bg .widget-title {
    color: var(--color-10);
  }
  
  .color1-bg .widget-content, .color1-bg .footer-text {
    color: var(--color-10);
    text-decoration: none;
  }
  
  
  .color2-bg .header-home-title, .color2-bg .header-home-subtitle, .color2-bg .header-page-title, .color2-bg .header-page-subtitle, .color2-bg .concept-content-title, .color2-bg .default-content-title {
    color: var(--color-10);
  }
  
  .color2-bg .concept-content-summary, .color2-bg .default-content-summary {
    color: var(--color-10);
  }
  
  .color2-bg .widget-title {
    color: var(--color-6);
  }
  
  .color2-bg .widget-content, .color2-bg .footer-text {
    color: var(--color-10);
    text-decoration: none;
  }
  
  .color3-bg .header-home-title, .color3-bg .header-home-subtitle, .color3-bg .header-page-title, .color3-bg .header-page-subtitle, .color3-bg .header-page-title, .color3-bg .concept-content-title, .color3-bg .default-content-title {
    color: var(--color-10);
  }
  
  .color3-bg .concept-content-summary, .color3-bg .default-content-summary {
    color: var(--color-10);
  }
  
  .color4-bg .header-home-title, .color4-bg .header-home-subtitle, .color4-bg .header-page-title, .color4-bg .header-page-subtitle, .color4-bg .concept-content-title, .color4-bg .default-content-title {
    color: var(--color-10);
  }
  
  .color4-bg .concept-content-summary, .color4-bg .default-content-summary {
    color: var(--color-10);
  }
  
  .color5-bg .header-home-title, .color5-bg .header-home-subtitle, .color5-bg .header-page-title, .color5-bg .header-page-subtitle, .color5-bg .concept-content-title, .color5-bg .default-content-title {
    color: var(--color-2);
  }
  
  .color5-bg .concept-content-summary, .color5-bg .default-content-summary {
    color: var(--color-10);
  }
  
  .color6-bg .header-home-title, .color6-bg .header-home-subtitle, .color6-bg .header-page-title, .color6-bg .header-page-subtitle,  .color6-bg .concept-content-title, .color6-bg .default-content-title {
    color: var(--color-2);
  }
  
  .color6-bg .concept-content-summary, .color6-bg .default-content-summary {
    color: var(--color-10);
  }
  
  .white-color2-bg .header-home-title, .white-color2-bg .header-home-subtitle, .white-color2-bg .header-page-title, .white-color2-bg .header-page-subtitle, .white-color2-bg .concept-content-title, .white-color2-bg .default-content-title {
    color: var(--color-2);
  }
  
  .white-color2-bg .concept-content-summary, .white-color2-bg .default-content-summary {
    color: var(--color-21);
  }
  
  .section-concept .nav-tabs {
    --bs-nav-tabs-border-color: var(--color-transparent);
    /*--bs-nav-tabs-border-width: 4px;*/
  }
  
  .section-concept .nav-tabs .nav-link {
    border: 0;
    color: var(--color-11);
    background-color: var(--color-transparent);
    font-weight: normal;
  }
  
  .section-concept .nav-tabs .nav-link.active {
    color: #675984;
    font-weight: bold;
  }
  
  .section-concept .nav-tabs .nav-link.active {
    border: 0;
    border-bottom: 4px solid #675984;
    border-color: #675984;
  }
  
  .white-color2-bg .nav-tabs .nav-link {
    border: 0;
    border-bottom: 4px solid var(--color-12);
    color: var(--color-21);
    font-weight: normal;
  }
  
  .white-color2-bg .nav-tabs .nav-link.active {
    color: var(--color-1);
    font-weight: bold;
  }
  
  .white-color2-bg .nav-tabs .nav-link.active {
    border: 0;
    border-bottom: 4px solid var(--color-1);
    border-color: var(--color-1);
  }
  
  .section-concept .btn.btn-cta {
    background-color: var(--color-1);
    border: 1px solid var(--color-1);
    color: var(--color-11);
  }
  
  .section-concept .btn.btn-cta:hover {
    background-color: var(--color-4);
    border: 1px solid var(--color-4);
    color: var(--color-10);
  }
  
  .section-concept .btn-group.btn-cta .btn, .section-concept .btn-group.btn-cta a, .section-concept .btn-group.btn-cta a .btn {
    color: inherit !important;
  }
  
  .section-concept .btn-group.btn-cta.open .dropdown-item:hover {
    background-color: var(--color-4) !important;
    color: var(--color-10) !important;
  }
  
  /* ** Button in Panel ** */
  
  /* ** COLOR 1 ** */
  
  .color1-bg .btn.btn-cta, .color1-bg .btn-group.btn-cta {
    background-color: var(--color-6) !important;
    border: 1px solid var(--color-6) !important;
    color: var(--color-2) !important;
  }
  
  .color1-bg .btn.btn-cta, .color1-bg .btn-group.btn-cta:hover {
    background-color: var(--color-5) !important;
    border: 1px solid var(--color-5) !important;
    color: var(--color-2) !important;
  }
  
  .color1-bg .btn-group.btn-cta .btn, .color1-bg .btn-group.btn-cta a, .color1-bg .btn-group.btn-cta a .btn {
    color: inherit !important;
  }
  
  .color1-bg .btn-group.btn-cta.open .dropdown-item:hover {
    background-color: var(--color-5) !important;
    color: var(--color-2) !important;
  }
  
  /* ** COLOR 2 ** */
  
  .color2-bg .btn.btn-cta, .color2-bg .btn-group.btn-cta {
    background-color: var(--color-4) !important;
    border: 1px solid var(--color-4) !important;
    color: var(--color-11) !important;
  }
  
  .color2-bg .btn.btn-cta, .color2-bg .btn-group.btn-cta:hover {
    background-color: var(--color-6) !important;
    border: 1px solid var(--color-6) !important;
    color: var(--color-10) !important;
  }
  
  .color2-bg .btn-group.btn-cta .btn, .color2-bg .btn-group.btn-cta a, .color2-bg .btn-group.btn-cta a .btn {
    color: inherit !important;
  }
  
  .color2-bg .btn-group.btn-cta.open .dropdown-item:hover {
    background-color: var(--color-6) !important;
    color: var(--color-10) !important;
  }
  
  /* ** COLOR 3 ** */
  
  .color3-bg .btn.btn-cta, .color3-bg .btn-group.btn-cta {
    background-color: var(--color-21) !important;
    border: 1px solid var(--color-21) !important;
    color: var(--color-11);
  }
  
  .color3-bg .btn.btn-cta, .color3-bg .btn-group.btn-cta:hover {
    background-color: var(--color-20) !important;
    border: 1px solid var(--color-20) !important;
    color: var(--color-10) !important;
  }
  
  .color3-bg .btn-group.btn-cta .btn, .color3-bg .btn-group.btn-cta a, .color3-bg .btn-group.btn-cta a .btn {
    color: inherit !important;
  }
  
  .color3-bg .btn-group.btn-cta.open .dropdown-item:hover {
    background-color: var(--color-20) !important;
    color: var(--color-10) !important;
  }
  
  /* ** COLOR 4 ** */
  
  .color4-bg .btn.btn-cta, .color4-bg .btn-group.btn-cta {
    background-color: var(--color-21) !important;
    border: 1px solid var(--color-21) !important;
    color: var(--color-11);
  }
  
  .color4-bg .btn.btn-cta, .color4-bg .btn-group.btn-cta:hover {
    background-color: var(--color-21) !important;
    border: 1px solid var(--color-21) !important;
    color: var(--color-10) !important;
  }
  
  .color4-bg .btn-group.btn-cta .btn, .color4-bg .btn-group.btn-cta a, .color4-bg .btn-group.btn-cta a .btn {
    color: inherit !important;
  }
  
  .color4-bg .btn-group.btn-cta.open .dropdown-item:hover {
    background-color: var(--color-21) !important;
    color: var(--color-10) !important;
  }
  
  /* ** COLOR 5 ** */
  
  .color5-bg .btn.btn-cta, .color5-bg .btn-group.btn-cta {
    background-color: var(--color-4) !important;
    border: 1px solid var(--color-4) !important;
    color: var(--color-11);
  }
  
  .color5-bg .btn.btn-cta, .color5-bg .btn-group.btn-cta:hover {
    background-color: var(--color-2) !important;
    border: 1px solid var(--color-2) !important;
    color: var(--color-10) !important;
  }
  
  .color5-bg .btn-group.btn-cta .btn, .color5-bg .btn-group.btn-cta a, .color5-bg .btn-group.btn-cta a .btn {
    color: inherit !important;
  }
  
  .color5-bg .btn-group.btn-cta.open .dropdown-item:hover {
    background-color: var(--color-2) !important;
    color: var(--color-10) !important;
  }
  
  /* ** COLOR 6 ** */
  
  .color6-bg .btn.btn-cta, .color6-bg .btn-group.btn-cta {
    background-color: var(--color-2) !important;
    border: 1px solid var(--color-2) !important;
    color: var(--color-11);
  }
  
  .color6-bg .btn.btn-cta, .color6-bg .btn-group.btn-cta:hover {
    background-color: var(--color-2) !important;
    border: 1px solid var(--color-2) !important;
    color: var(--color-10) !important;
  }
  
  .color6-bg .btn-group.btn-cta .btn, .color6-bg .btn-group.btn-cta a, .color6-bg .btn-group.btn-cta a .btn {
    color: inherit !important;
  }
  
  .color6-bg .btn-group.btn-cta.open .dropdown-item:hover {
    background-color: var(--color-20) !important;
    color: var(--color-10) !important;
  }
  
  /* ** WHITE COLOR 2 ** */
  
  .white-color2-bg .btn.btn-cta, .white-color2-bg .btn-group.btn-cta {
    background-color: var(--color-2) !important;
    border: 1px solid var(--color-2) !important;
    color: var(--color-11);
  }
  
  .white-color2-bg .btn.btn-cta, .white-color2-bg .btn-group.btn-cta:hover {
    background-color: var(--color-4) !important;
    border: 1px solid var(--color-4) !important;
    color: var(--color-10) !important;
  }
  
  .white-color2-bg .btn-group.btn-cta .btn, .white-color2-bg .btn-group.btn-cta a, .white-color2-bg .btn-group.btn-cta a .btn {
    color: inherit !important;
  }
  
  .white-color2-bg .btn-group.btn-cta.open .dropdown-item:hover {
    background-color: var(--color-4) !important;
    color: var(--color-10) !important;
  }
  
  /* ** BUTTON ONLY COLOR 3 ** */
  
  .btn-cta.btn-color3 {
    background-color: var(--color-3) !important;
    border: 1px solid var(--color-3) !important;
    color: var(--color-11) !important;
  }
  
  .btn-cta.btn-color3:hover {
    background-color: var(--color-20) !important;
    border: 1px solid var(--color-20) !important;
    color: var(--color-10) !important;
  }
  
  .btn-cta.btn-color3 .btn, .btn-cta.btn-color3 a, .btn-cta.btn-color3 a .btn {
    color: inherit !important;
  }
  
  .btn-cta.btn-color3.open .dropdown-item:hover {
    background-color: var(--color-20) !important;
    color: var(--color-10) !important;
  }
  
  /* ** BUTTON ONLY COLOR 4 ** */
  
  .btn-cta.btn-color4 {
    background-color: var(--color-4) !important;
    border: 1px solid var(--color-4) !important;
    color: var(--color-11) !important;
  }
  
  .btn-cta.btn-color4:hover {
    background-color: var(--color-11) !important;
    border: 1px solid var(--color-4) !important;
    color: var(--color-4) !important;
  }
  
  .btn-cta.btn-color4 .btn, .btn-cta.btn-color4 a, .btn-cta.btn-color4 a .btn {
    color: inherit !important;
  }
  
  .btn-cta.btn-color4.open .dropdown-item:hover {
    background-color: var(--color-11) !important;
    color: var(--color-4) !important;
  }



/*** Top Navbar ***/

nav.top-navbar,
nav.navbar-dark.color2-bg {
  background-color: var(--color-2);
  color: var(--color-10);
}

@media (max-width: 767px) {
  .top-navbar #navcol-2 {
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .top-navbar .nav-item {
    margin-top: 5px;
    margin-bottom: 5px;
    display: block;
    max-width: 33.3%;
    height: 40px;
    overflow: hidden;
  }
}

@media (max-width: 767px) {
  .top-navbar .nav-link {
    border: 0;
    color: var(--color-12);
    display: flex;
    font-weight: normal;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 767px) {
  .top-navbar .nav-link .fa, .top-navbar .nav-link .fas, .top-navbar .nav-link .far {
    font-size: 2em;
  }
}

.section-concept.blue-bg .nav-tabs .nav-link, .top-navbar.blue-bg .nav-link {
  border: 0;
  color: var(--color-12);
  font-weight: normal;
}

.section-concept.blue-bg .nav-tabs .nav-link.active, .top-navbar.blue-bg .nav-link.active {
  color: var(--color-10);
  font-weight: bold;
}

nav.top-navbar {
  background-color: var(--color-2);
  color: var(--color-10);
}

@media (max-width: 767px) {
  .top-navbar #navcol-2 {
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .top-navbar .nav-item {
    margin-top: 5px;
    margin-bottom: 5px;
    display: block;
    max-width: 33.3%;
    height: 40px;
    overflow: hidden;
  }
}

@media (max-width: 767px) {
  .top-navbar .nav-link {
    border: 0;
    color: var(--color-12);
    display: flex;
    font-weight: normal;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 767px) {
  .top-navbar .nav-link .fa, .top-navbar .nav-link .fas, .top-navbar .nav-link .far {
    font-size: 2em;
  }
}

nav.navbar {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 768px) {
  nav.navbar {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1200px) {
  nav.navbar {
    padding-left: 95px;
    padding-right: 95px;
  }
}

.navbar-brand {
  max-width: 50px;
}

.color2-bg .nav-item, .color2-bg .nav-link {
  color: var(--color-10) !important;
}

.borders-rounded {
  border-radius: 25px;
  transition: transform 1s;
  overflow: hidden;
}

.borders-rounded:hover {
  transform: scale(1.025);
}

.borders-rounded-sm {
  border-radius: 7px;
  overflow: hidden;
  box-shadow: 3px 3px 15px rgba(0,0,0, .1);
}

  
  .section-iframe {
    overflow: hidden;
    background-color: var(--color-13);
  }
  
  @media (min-width: 1200px) {
    .section-iframe {
      height: 420px;
      background-color: var(--color-14);
    }
  }
  
  .iframe-block {
    position: relative;
    height: 360px;
    padding-top: 56.25%;
  }
  
  @media (min-width: 768px) {
    .iframe-block {
      height: 280px;
      padding-top: 0;
    }
  }
  
  @media (min-width: 992px) {
    .iframe-block {
      height: 320px;
      padding-top: 0;
    }
  }
  
  @media (min-width: 1200px) {
    .iframe-block {
      height: 100%;
      padding-top: 0;
    }
  }
  
  .iframe-block iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .video-block {
    position: relative;
    height: 0;
    padding-top: 56.25%;
  }
  
  .video-block video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
    
  header .header-home-logo-block {
    max-width: 280px;
    margin: 0 0 25px;
  }
  
  @media (min-width: 768px) {
    header .header-home-logo-block {
      max-width: 280px;
      margin: 0 0 25px;
    }
  }
  
  .concept-content-image-block img {
    height: 64px;
  }
  
  .concept-content-image-block {
    margin-bottom: 15px;
  }
  
  header.header-page.large-bg {
    height: 50vh;
  }
  
  @media (min-width: 768px) {
    header.header-page.large-bg {
      height: 60vh;
    }
  }
  
  @media (min-width: 1200px) {
    header.header-page.large-bg {
      height: 70vh;
    }
  }
  
  @media (min-width: 1400px) {
    header.header-page.large-bg {
      height: 80vh;
    }
  }
  
  .ratings {
    color: var(--color-4);
  }
  
  