  :root {
    --color-1: #378465;
    --color-2: #245843;
    --color-3: #DD5042;
    --color-4: #E88F4F;
    --color-5: #80C19C;
    --color-6: #C3D86C;
    --color-7: #99DCE8;
    --color-10: #fff;
    --color-11: #fdfdfd;
    --color-12: #999;
    --color-13: #f4f4f4;
    --color-14: #DBDBDB;
    --color-15: #e4e4e4;
    --color-20: #000;
    --color-21: #333;
    --color-transparent: transparent;
    --bg-overlay-color-1: rgba(0,0,0,.25);
    --bg-overlay-color-2: rgba(0,0,0,.5);
    --bg-overlay-color-3: rgba(0,0,0,.75);
    /* --bs-nav-link-font-size: .9rem; */
    --bs-btn-font-family: 'Akrobat', tahoma, sans-serif;
  }

* {
  font-family: var(--bs-btn-font-family);
}

:root {
  --bs-primary: var(--color-21);
}

#app {
  min-height: 100vh;
}

main {
  min-height: 100vh;
}

@media (min-width: 768px) {
  main {
    margin-bottom: 100px;
  }
}

.ratings {
  color: var(--color-4);
}

.logo {
  max-width: 150px;
  margin: 15px auto;
}

.line-bottom-white-xs {
  border-bottom: 1px dashed white;
}
.line-bottom-white-lg {
  border-bottom: 10px dashed white;
}

a {
  text-decoration: none;
  color: var(--color-21);
}

a:hover {
  color: var(--color-20);
}

.pt-7 {
  padding-top: 70px;
}

.dropdown-item {
  font-size: var(--bs-nav-link-font-size);
}

header.header-home {
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

@media (min-width: 768px) {
  header.header-home {
    min-height: 400px;
  }
}

@media (min-width: 1200px) {
  header.header-home {
    min-height: 602px;
  }
}

@media (min-width: 1400px) {
  header.header-home {
    min-height: 720px;
  }
}

header.header-home .header-overlay-block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
}

.bg-overlay1 {
  background-color: var(--bg-overlay-color-1);
}

.bg-overlay2 {
  background-color: var(--bg-overlay-color-2);
}

.bg-overlay3 {
  background-color: var(--bg-overlay-color-3);
}

header.header-home .header-media-block {
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 8;
  overflow: hidden;
}

.header-media-block {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 8;
  overflow: hidden;
}

.header-media-block .image-block {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 8;
}

@media (min-width: 1200px) {
  header.header-home .header-media-block, 
  header.header-home .header-media-block .image-block {
    background-position: 50% 50%, center center;
  }
}

header.header-home .header-content-block {
  position: relative;
  z-index: 10;
  margin-top: 100px;
  margin-bottom: 100px;
}

header.header-home .header-home-title {
  font-weight: 800;
  font-size: 36px;
  line-height: 36px;
}

@media (min-width: 768px) {
  header.header-home .header-home-title {
    font-size: 42px;
    line-height: 42px;
  }
}

@media (min-width: 1200px) {
  header.header-home .header-home-title {
    font-size: 52px;
    line-height: 52px;
  }
}

@media (min-width: 1400px) {
  header.header-home .header-home-title {
    font-size: 72px;
    line-height: 72px;
  }
}

.header-home-subtitle {
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  margin-top: 15px;
}

@media (min-width: 768px) {
  .header-home-subtitle {
    font-size: 18px;
    line-height: 20px;
    width: 70%;
    margin-top: 10px;
  }
}

@media (min-width: 1200px) {
  .header-home-subtitle {
    font-size: 24px;
    line-height: 26px;
  }
}

@media (min-width: 1400px) {
  .header-home-subtitle {
    font-size: 28px;
    line-height: 30px;
  }
}

header.header-page {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 50px 20px;
  margin-top: 65px;
}

header.header-page .header-overlay-block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-overlay-color-1);
  z-index: 9;
}

header.header-page .header-media-block {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 8;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

header.header-page .header-content-block {
  padding: 15px;
  position: relative;
  z-index: 10;
}

header.header-page .header-page-title {
  font-weight: 800;
  font-size: 24px;
  line-height: 27px;
}

@media (min-width: 768px) {
  header.header-page .header-page-title {
    font-size: 32px;
    line-height: 36px;
  }
}

@media (min-width: 1200px) {
  header.header-page .header-page-title {
    font-size: 36px;
    line-height: 39px;
  }
}

@media (min-width: 1400px) {
  header.header-page .header-page-title {
    font-size: 42px;
    line-height: 45px;
  }
}

header.header-trips {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 50px 0;
  margin-top: 0;
  margin-bottom: 0;
}

header.header-trips .header-overlay-block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(30, 94, 80,.7);
  z-index: 9;
  opacity: 1;
}

@media screen and (min-width: 576px) {
  header.header-trips .header-overlay-block {
    opacity: 0;
  }
}

header.header-trips .header-media-block {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 8;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

header.header-trips .header-content-block {
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
  z-index: 10;
}

header.header-trips .header-trips-title {
  font-weight: 800;
  font-size: 24px;
  line-height: 27px;
}

@media (min-width: 768px) {
  header.header-trips .header-trips-title {
    font-size: 32px;
    line-height: 36px;
  }
}

@media (min-width: 1200px) {
  header.header-trips .header-trips-title {
    font-size: 36px;
    line-height: 39px;
  }
}

@media (min-width: 1400px) {
  header.header-trips .header-trips-title {
    font-size: 42px;
    line-height: 45px;
  }
}

.header-home-subtitle {
  display: block;
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
}

@media (min-width: 768px) {
  .header-home-subtitle {
    font-size: 18px;
    line-height: 20px;
    width: 90%;
    margin-top: 10px;
  }
}

@media (min-width: 1200px) {
  .header-home-subtitle {
    font-size: 24px;
    line-height: 28px;
  }
}

@media (min-width: 1400px) {
  .header-home-subtitle {
    font-size: 28px;
    line-height: 30px;
  }
}

.header-page-subtitle {
  display: block;
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
}

@media (min-width: 768px) {
  .header-page-subtitle {
    font-size: 18px;
    line-height: 20px;
    width: 90%;
    margin-top: 10px;
  }
}

@media (min-width: 1200px) {
  .header-page-subtitle {
    font-size: 24px;
    line-height: 28px;
  }
}

@media (min-width: 1400px) {
  .header-page-subtitle {
    font-size: 28px;
    line-height: 30px;
  }
}

.header-trips-subtitle {
  display: block;
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
}

@media (min-width: 768px) {
  .header-trips-subtitle {
    font-size: 18px;
    line-height: 20px;
    width: 90%;
    margin-top: 10px;
  }
}

@media (min-width: 1200px) {
  .header-trips-subtitle {
    font-size: 24px;
    line-height: 28px;
  }
}

@media (min-width: 1400px) {
  .header-trips-subtitle {
    font-size: 28px;
    line-height: 30px;
  }
}
.header-trips-subtitle {
  display: block;
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
}

@media (min-width: 768px) {
  .header-trips-subtitle {
    font-size: 18px;
    line-height: 20px;
    width: 90%;
    margin-top: 10px;
  }
}

@media (min-width: 1200px) {
  .header-trips-subtitle {
    font-size: 24px;
    line-height: 28px;
  }
}

@media (min-width: 1400px) {
  .header-trips-subtitle {
    font-size: 28px;
    line-height: 30px;
  }
}



.section-concept {
  padding: 40px 5px;
}

@media (min-width: 768px) {
  .section-concept {
    padding: 40px;
  }
}

@media (min-width: 1200px) {
  .section-concept {
    padding: 90px 95px;
  }
}

.faq-content-box {
  margin-bottom: 30px;
  padding-top: 30px;
}

@media (min-width: 992px) {
  .faq-content-box {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
}


.reviews-carousel .review-item {
  padding: 15px 10px;
  background-color: var(--color-10);
  border-radius: 7px;
  box-shadow: 4px 3px 5px var(--color-12);
  margin: 10px;
}


.review-item-details .review-item-info-block {
  padding-left: 10px;
  padding-right: 10px;
}

.review-item-details .review-item-info-block .review-item-date {
  font-size: 12px;
  margin-bottom: 0;
}

.review-item-details .review-item-info-block .review-item-title {
  font-size: 14px;
  font-weight: bold;
  color: var(--color-4);
  margin-bottom: 0;
}

.review-item-details .review-item-ratings-block ul.ratings {
  display: flex;
  margin-bottom: 0;
  padding: 0;
}

.review-item-details .review-item-ratings-block ul.ratings .rating-item {
  list-style: none;
}

.review-item-details .review-item-ratings-block ul.ratings .rating-item i {
  color: var(--color-4);
}

.review-item-details .review-item-ratings-block ul.ratings .rating-item i {
  color: var(--color-4);
}

/* ** ** */

div.list__trip-schedule {
  position: relative;

}

div.list__trip-schedule .list__delete-box {
  position: inherit;
  margin: 0 10px;
  margin-top: -25px;
  border-radius: 4px;
}
  

.lists .list-item {
  padding: 15px 10px;
  background-color: var(--color-10);
  border-radius: 7px;
  box-shadow: 1px 1px 3px var(--color-12);
  margin: 10px;
}
  
.list-item-details .list-item-bgimage-block {
  background-size: cover; 
  background-position: center center;
  overflow: hidden;
}
  
.list-item-details .list-item-image-block {
  width: 100px;
  overflow: hidden;
}

.list-item-details .list-item-button-block {
  padding-left: 10px;
  padding-right: 10px;
}

.list-item-details .list-item-info-block {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.list-item-details .list-item-info-block .list-item-subtitle {
  font-size: 12px;
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .list-item-details .list-item-info-block .list-item-subtitle {
    font-size: 15px;
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .list-item-details .list-item-info-block .list-item-subtitle {
    font-size: 15px;
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .list-item-details .list-item-info-block .list-item-subtitle {
    font-size: 17px;
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .list-item-details .list-item-info-block .list-item-subtitle {
    font-size: 18px;
    margin-bottom: 0;
  }
}

.list-item-details .list-item-info-block .list-item-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
  text-transform: uppercase;
}

@media (min-width: 576px) {
  .list-item-details .list-item-info-block .list-item-title {
    font-size: 19px;
  }
}

@media (min-width: 768px) {
  .list-item-details .list-item-info-block .list-item-title {
    font-size: 19px;
  }
}

@media (min-width: 992px) {
  .list-item-details .list-item-info-block .list-item-title {
    font-size: 21px;
  }
}

@media (min-width: 1200px) {
  .list-item-details .list-item-info-block .list-item-title {
    font-size: 24px;
  }
}

.list-item-details .list-item-ratings-block ul.ratings {
  display: flex;
  margin-bottom: 0;
  padding: 0;
}

.list-item-details .list-item-ratings-block ul.ratings .rating-item {
  list-style: none;
}

.list-item-details .list-item-ratings-block ul.ratings .rating-item i {
  color: var(--color-4);
}

.list-item-details .list-item-ratings-block ul.ratings .rating-item i {
  color: var(--color-4);
}

.default-image-box {
  border-radius: 15px;
  margin-bottom: 10px;
  background-position: center;
}

@media (min-width: 1200px) {
  .default-image-box {
    max-width: 350px;
  }
}

.box-image {
  background-size: cover; 
  background-position: center center;
}
.box-image img {
  opacity: .65;
}

.default-vertical-image-box {
  border-radius: 15px;
  width: 100%;
  height: 465px;
  margin-bottom: 10px;
  background-position: center;
}

@media (min-width: 768px) {
  .default-vertical-image-box {
    width: 100%;
    height: 465px;
  }
}

@media (min-width: 992px) {
  .default-vertical-image-box {
    width: 100%;
    height: 465px;
  }
}

@media (min-width: 1200px) {
  .default-vertical-image-box {
    width: 100%;
    max-width: 350px;
    height: 465px;
  }
}

.default-content-title {
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
}

@media (min-width: 1200px) {
  .default-content-title {
    font-size: 28px;
    line-height: 32px;
  }
}

.default-content-subtitle {
  font-size: 15px;
  line-height: 21px;
  font-weight: 600;
  color: #000 !important;
}

@media (min-width: 768px) {
  .default-content-subtitle {
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
  }
}

.default-content-summary {
  font-size: 15px;
  line-height: 21px;
  font-weight: 400;
}

@media (min-width: 768px) {
  .default-content-summary {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
  }
}

.concept-content-block {
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
}

@media (min-width: 576px) {
  .concept-content-block {
    width: 100%;
    text-align: center;
    display: block;
  }
}

@media (min-width: 768px) {
  .concept-content-block {
    width: auto;
    text-align: start;
  }
}

@media (min-width: 1200px) {
  .concept-content-block {
    width: auto;
    text-align: start;
    display: flex;
    justify-content: center;
  }
}

.concept-content-title {
  font-size: 32px;
  line-height: 36px;
  font-weight: 800;
}

@media (min-width: 768px) {
  .concept-content-title {
    font-size: 42px;
    line-height: 46px;
    font-weight: 800;
  }
}

@media (min-width: 1200px) {
  .concept-content-title {
    font-size: 52px;
    line-height: 56px;
    font-weight: 800;
  }
}

@media (min-width: 1400px) {
  .concept-content-title {
    font-weight: 800;
  }
}

.concept-content-summary {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .concept-content-summary {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
  }
}


/* * */

.footer {
  padding: 20px;
}

@media (min-width: 768px) {

  .footer {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }
}

.widget-title {
  color: var(--color-20);
  font-weight: 800;
  font-size: 19px;
  line-height: 22px;
}

@media (min-width: 768px) {
  .widget-title {
    font-size: 21px;
    line-height: 24px;
  }
}

@media (min-width: 1200px) {
  .widget-title {
    font-size: 26px;
    line-height: 29px;
  }
}

@media (min-width: 1400px) {
  .widget-title {
    font-size: 26px;
    line-height: 29px;
  }
}

.widget-content {
  color: var(--color-21);
  display: block;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
}

@media (min-width: 768px) {
  .widget-content {
    font-size: 17px;
    line-height: 20px;
  }
}

@media (min-width: 1200px) {
  .widget-content {
    font-size: 19px;
    line-height: 22px;
  }
}

@media (min-width: 1400px) {
  .widget-content {
    font-size: 21px;
    line-height: 24px;
  }
}

.footer-text {
  color: var(--bs-light);
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

.section-concept.invert-order .col-md-6:first-child {
  order: 1;
}

.section-concept.invert-order .col-xl-5:first-child {
  order: 1;
}

@media (min-width: 992px) {
  .section-concept.invert-order .concept-content-box {
    padding-left: 70px;
  }
}

.section-iframe {
  overflow: hidden;
  background-color: var(--color-13);
}

@media (min-width: 1200px) {
  .section-iframe {
    min-height: 540px;
    background-color: var(--color-14);
  }
}

.iframe-block {
  position: relative;
  min-height: 360px;
  padding-top: 56.25%;
}

@media (min-width: 768px) {
  .iframe-block {
    min-height: 280px;
    padding-top: 0;
  }
}

@media (min-width: 992px) {
  .iframe-block {
    min-height: 320px;
    padding-top: 0;
  }
}

@media (min-width: 1200px) {
  .iframe-block {
    height: 100%;
    padding-top: 0;
  }
}

.iframe-block iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-block {
  position: relative;
  height: 0;
  padding-top: 56.25%;
}

.video-block video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*** Slick Carousel ***/
.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.slick-slide img {
  display: inline-block;
}

.slick-dots {
  bottom: 10px;
}

.vertical-dots {
  right: 10px;
  list-style: none;
  display: block;
  position: absolute;
  bottom: 25px;
  text-align: right;
}

.vertical-dots li {
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.vertical-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: var(--color-transparent);
  border: 0;
  outline: none;
  background: var(--color-transparent);
}

.vertical-dots li button:hover, .vertical-dots li button:focus {
  outline: none;
}

.vertical-dots li button:hover:before, .vertical-dots li button:focus:before {
  opacity: 1;
}

.vertical-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: var(--color-20);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.vertical-dots li.slick-active button:before {
  opacity: .75;
  color: var(--color-20);
}

.reviews-carousel .slick-dots {
  bottom: -25px;
}

.reviews-carousel .slick-slide {
  height: auto;
}

.grid-item {
  width: 100%;
}

.grid-item--width2 {
  width: 100%;
}

@media (min-width: 480px) {
  .grid-item {
    width: 200px;
  }
}

@media (min-width: 1215px) {
  .grid-item--width2 {
    width: 400px;
  }
}

.grid-item a {
  padding: 5px;
}

.section-image .full-image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.section-image {
  position: relative;
  height: 360px;
}

@media (min-width: 1200px) {
  .section-image {
    height: 650px;
  }
}

nav.navbar {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 768px) {
  nav.navbar {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1200px) {
  nav.navbar {
    padding-left: 95px;
    padding-right: 95px;
  }
}

.navbar-shadow {
  box-shadow: 0 1px 2px var(--color-15);
}

.navbar-brand {
  max-width: 50px;
}


nav.navbar .nav-item {
  text-transform: uppercase;
}

@media (min-width: 1200px) {
  .navbar-brand {
    max-width: 64px;
  }
}

@media (min-width:1200px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 20px;
    padding-left: 20px;
  }
}

header .header-home-logo-block {
  max-width: 125px;
  margin: 15px auto;
}

@media (min-width: 768px) {
  header .header-home-logo-block {
    max-width: 175px;
    margin: 15px 0;
  }
}

@media (min-width: 1440px) {
  header .header-home-logo-block {
    max-width: 200px;
    margin: 15px 0;
  }
}

.concept-content-icon-block img {
  height: 64px;
}

.concept-content-image-block {
  margin-bottom: 15px;
}

/*** Text Utilities ***/
.break {
  flex-basis: 100%;
  height: 0;
}

.text-oneline {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.text-twoline {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-threeline {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}


#scroll-top {
  position: fixed;
  bottom: 5px;
  right: 5px;
}


/*** Embeded ***/

.myembeded-box {
	width:  100%;
	height: auto;
	margin: auto;
  border: 1px solid rgba(160, 189, 153, 0.5);
  background-color: rgba(160, 189, 153, 0.5);
  border-radius: 7px;
	overflow: hidden;
}
.myembeded {
	position:  relative;
	height:  100%;
	padding-top:  56.25%;
}
.myembeded iframe {
	position:  absolute;
	top:  0;
	left:  0;
	width:  100%;
	height:  100%;
}

.myvideo-box {
	width:  100%;
	height: auto;
	margin: auto;
    border: 1px solid rgba(255,255,255,0.15);
    border-radius: 7px;
	overflow: hidden;
}
.myvideo {
	position:  relative;
	height:  0;
	padding-top:  56.25%;
}
.myvideo iframe {
	position:  absolute;
	top:  0;
	left:  0;
	width:  100%;
	height:  100%;
}


.card {
  border: none !important;
}

a.filepond--credits {
  display: none;
  }

.page-item .page-link {
    border-radius: 0;
    margin-right: 5px;
    color: var(--bs-dark-green);
}
.page-item.active .page-link {
  color: var(--bs-light);
  background-color: var(--bs-dark-green);
  border-color: var(--bs-dark-green);

}

.bg-light-opacity {
  background-color: rgba(255, 255, 255, .9) !important;
}

.friend-box {
  background-color: var(--bs-gray-200);
  border-radius: 7px;
  display: flex !important;
  justify-content: start;
  align-content: center;
}

.friend-box .friend-image-box {
  width: 60px;
  display: flex;
  justify-content: start;
  align-content: center;

}
.friend-box .friend-content-box {
  width: calc(100% - 60px);
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
  padding: 5px;
  
}

.invitation-box {
  background-color: var(--bs-gray-200);
  border-radius: 7px;
}
.invitation-box .invitation-image-box {
  display: flex;
  align-items: start;
  justify-content: start;

}

.invitation-box .invitation-image-box picture {
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.invitation-box .invitation-image-box div {
  width: calc(100%-32px);
  display: flex;
  align-items: start;
  justify-content: center;
}

.invitation-box .invitation-content-box {
  display: block;
  text-align: start;
  padding-left: 7px;
  padding-right: 7px;
  margin-bottom: 10px;
  
}

h3.card-details-title,
h3.card-list-title {
  font-size: 19px;
  font-weight: 300;
  color: var(--bs-dark);
}

/*** Items Carousel ***/
.items-carousel {}
.items-carousel .slick-dots {
  position: relative;
  top: 0;
}
.items-carousel .slick-dots li button {
  background-color: var(--bs-light);
  border-radius: 50%;
}
.items-carousel a {
  color: var(--color-2);
}
.items-carousel a:hover {
  color: var(--color-1);
}

.items-carousel .item-carousel {
  margin: 5px;
  background-color: var(--bs-light);
  border-radius: 25px;
  overflow: hidden;
  border: 1px solid rgba(0,0,0,.05);
}

.item-carousel .item-carousel-image {}
.item-carousel-image img {}

.item-carousel .item-carousel-content {
  padding: 5px 15px;
}
.item-carousel-content .item-carousel-content-title {
  font-size: 17px;
  font-weight: bold;
}
.item-carousel-content .item-carousel-content-subtitle {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--bs-gray-600) !important;
}
.item-carousel-content .item-carousel-content-description {
  font-size: 14px;
  font-weight: normal;
  color: var(--bs-gray-600) !important;
  height: 40px;
  overflow: hidden;
}

/*** Header ***/

.header {}

.header .header-content {}

.header-content .header-content-title {
  font-size: 42px;
  font-weight: bold;
  color: var(--color-6);    
}
.header-content .header-content-subtitle {
  font-size: 17px;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--bs-gray-200) !important;
}
.header-content .header-content-description {
  font-size: 21px;
  font-weight: normal;
  color: var(--bs-light) !important;
}

.banner-dark-green {
  padding: 15px;
  border-radius: 14px;
  background-color: var(--color-2) !important;
  color: var(--bs-light) !important;
  border-color: var(--color-2) !important;
  opacity: .9;
}

.w-20 {
  width: 20%;
}

.w-80 {
  width: 80%;
}

.box-content__title {
  font-weight: 600;
  font-size: 1.2rem;
}
.box-content__category {
  font-weight: 400;
  font-size: .9rem;
}
.box-content__creator {
  font-weight: 400;
  font-size: .9rem;
}