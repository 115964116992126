@font-face {
    font-family: 'Akrobat';
    src: url('../fonts/subset-Akrobat-Thin.eot');
    src: url('../fonts/subset-Akrobat-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-Akrobat-Thin.woff2') format('woff2'),
        url('../fonts/subset-Akrobat-Thin.woff') format('woff'),
        url('../fonts/subset-Akrobat-Thin.ttf') format('truetype'),
        url('../fonts/subset-Akrobat-Thin.svg#Akrobat-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Akrobat';
    src: url('../fonts/subset-Akrobat-Black.eot');
    src: url('../fonts/subset-Akrobat-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-Akrobat-Black.woff2') format('woff2'),
        url('../fonts/subset-Akrobat-Black.woff') format('woff'),
        url('../fonts/subset-Akrobat-Black.ttf') format('truetype'),
        url('../fonts/subset-Akrobat-Black.svg#Akrobat-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Akrobat';
    src: url('../fonts/subset-Akrobat-Bold.eot');
    src: url('../fonts/subset-Akrobat-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-Akrobat-Bold.woff2') format('woff2'),
        url('../fonts/subset-Akrobat-Bold.woff') format('woff'),
        url('../fonts/subset-Akrobat-Bold.ttf') format('truetype'),
        url('../fonts/subset-Akrobat-Bold.svg#Akrobat-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Akrobat';
    src: url('../fonts/subset-Akrobat-ExtraBold.eot');
    src: url('../fonts/subset-Akrobat-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-Akrobat-ExtraBold.woff2') format('woff2'),
        url('../fonts/subset-Akrobat-ExtraBold.woff') format('woff'),
        url('../fonts/subset-Akrobat-ExtraBold.ttf') format('truetype'),
        url('../fonts/subset-Akrobat-ExtraBold.svg#Akrobat-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Akrobat';
    src: url('../fonts/subset-Akrobat-ExtraLight.eot');
    src: url('../fonts/subset-Akrobat-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-Akrobat-ExtraLight.woff2') format('woff2'),
        url('../fonts/subset-Akrobat-ExtraLight.woff') format('woff'),
        url('../fonts/subset-Akrobat-ExtraLight.ttf') format('truetype'),
        url('../fonts/subset-Akrobat-ExtraLight.svg#Akrobat-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Akrobat';
    src: url('../fonts/subset-Akrobat-Light.eot');
    src: url('../fonts/subset-Akrobat-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-Akrobat-Light.woff2') format('woff2'),
        url('../fonts/subset-Akrobat-Light.woff') format('woff'),
        url('../fonts/subset-Akrobat-Light.ttf') format('truetype'),
        url('../fonts/subset-Akrobat-Light.svg#Akrobat-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Akrobat';
    src: url('../fonts/subset-Akrobat-Regular.eot');
    src: url('../fonts/subset-Akrobat-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-Akrobat-Regular.woff2') format('woff2'),
        url('../fonts/subset-Akrobat-Regular.woff') format('woff'),
        url('../fonts/subset-Akrobat-Regular.ttf') format('truetype'),
        url('../fonts/subset-Akrobat-Regular.svg#Akrobat-Regular') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Akrobat';
    src: url('../fonts/subset-Akrobat-SemiBold.eot');
    src: url('../fonts/subset-Akrobat-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-Akrobat-SemiBold.woff2') format('woff2'),
        url('../fonts/subset-Akrobat-SemiBold.woff') format('woff'),
        url('../fonts/subset-Akrobat-SemiBold.ttf') format('truetype'),
        url('../fonts/subset-Akrobat-SemiBold.svg#Akrobat-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

