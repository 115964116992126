
/*** FILE: styles.css ***/
/*** UPDATED: FEB 16, 2024 ***/

/*** FORM ***/ 
@media (min-width: 576px) {
    .contact-app {
      min-height: 300px;
      background-color: #fff;
      /*color: var(--bs-white);*/
      border: 1px solid #ccc;
      border-radius: 7px;
      padding: 30px 15px;
    }
  }
  
  .contact-app {
    min-height: 300px;
    background-color: #fff;
    /*color: var(--bs-white);*/
    border: 1px solid #ccc;
    border-radius: 7px;
    padding: 20px 10px;
  }
  
  form {
    width: 100%;
    border: none;
  }
  
  .input-group-text {
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #ccc;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0;
    align-items: start;
  }
  
  .form-control {
    color: #212529;
    background-color: transparent;
    border-color: transparent;
    outline: 0;
    box-shadow: none;
  }
  
  textarea.form-control, select.form-control, input.form-control {
    border: 1px solid #ccc;
    border-radius: 4px !important;
  }
  
  @media (min-width: 1200px) {
    span.input-group-text {
      /*border: 1px solid #ccc;*/
      border-radius: 4px !important;
      min-width: 75px;
    }
  }
  
  @media (max-width: 1199px) {
    span.input-group-text .fa-2x {
      font-size: 1rem;
    }
  }
  
  span.input-group-text {
    /*border: 1px solid #ccc;*/
    border-radius: 4px !important;
    min-width: 25px;
  }
  
  .input-group {
    margin-bottom: 20px;
  }
  
/*** SLICK ***/ 
/* Slick Vertical Dots */

.vertical-dots {
    right: -10px;
    list-style: none;
    display: block;
    position: absolute;
    top: 40%;
    margin-top: -10px;
    text-align: right;
  }
  
  .vertical-dots li {
    position: relative;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  
  .vertical-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }
  
  .vertical-dots li button:hover, .vertical-dots li button:focus {
    outline: none;
  }
  
  .vertical-dots li button:hover:before, .vertical-dots li button:focus:before {
    opacity: 1;
  }
  
  .vertical-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .vertical-dots li.slick-active button:before {
    opacity: .75;
    color: black;
  }
  
  a.btn-link:hover {
    text-decoration: none;
  }
    

/*** STYLES ***/
body {
    font-size: 18px;
    color: #1D252C;
  }
  
  p.font-bold {
    font-weight: bold;
  }
  
  .container {
    max-width: 1200px;
  }
  
  a {
    color: #1D252C;
    text-decoration: none;
  }
  
  .nav.navbar-nav.main-nav {
    display: flex;
    margin: 0 auto;
    width: 100%;
    justify-content: space-evenly;
  }
  
  .nav.navbar-nav.main-nav {
    list-style: disc outside;
    list-style-type: disc;
    list-style-position: outside;
    list-style-image: initial;
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: center;
  }
  
  @media (min-width: 992px) {
    .nav.navbar-nav.main-nav {
      padding: 0 10px;
    }
  }
  
  @media (min-width: 1200px) {
    .nav.navbar-nav.main-nav {
      /*padding: 0;*/
    }
  }

  .dropdown-item.active, .dropdown-item:active {
    background-color: var(--color-1);
    color: var(--color-10);
}

  .navbar.navbar-light {
    background-color: #F5F7FA;
  }
  
  .navbar-light .navbar-nav > li > a {
    color: #1D252C;
    text-decoration: none;
    font-size: 18px;
  }
  
  .navbar-default .navbar-nav > li > a:hover {
    text-decoration: underline;
  }
  
  .navbar-light .navbar-nav > li > a:focus, .navbar-light .navbar-nav > li > a:hover {
    font-family: 'Inter', sans-serif;
    color: #1D252C;
    /*background: #ffffff;*/
    text-decoration: underline;
    font-size: 18px;
    font-weight: 400;
    display: inline-block;
    margin: 0;
    text-align: center;
    transition: all .3s;
  }
  
  .top-nav {
    padding: 7px 14px;
    /*background-color: #000000;*/
  }
  
  .btn-link, .btn-link:focus, .btn-link:active, .btn-link:visited {
    color: #1D252C;
    text-decoration: none;
  }
  
  .btn-link:hover {
    color: #1D252C;
    text-decoration: underline;
  }

  .btn-cta, .btn-cta:focus, .btn-cta:active {
    border: 1px solid var(--color-1);;
    background: var(--color-1);
    color: var(--bs-white);
    display: inline-flex;
    text-decoration: none;
  }
  
  .btn-cta:hover {
    border: 1px solid var(--color-1);;
    background: var(--bs-white);
    color: var(--color-1);
    display: inline-flex;
    text-decoration: none;
  }
  
  .btn-default, .btn-default:focus, .btn-default:active, .btn-default:visited {
    border: 1px solid #4f5d75;
    background-color: #4f5d75;
    color: #ffffff;
    border-radius: 83px;
    padding: 7px 22px;
    font-size: 18px;
  }
  
  .btn-default:hover {
    border: 1px solid #4f5d75;
    background-color: #ffffff;
    color: #4f5d75;
  }
  
  .btn-close, .btn-close:focus, .btn-close:active {
    border: none;
    background: none;
    display: inline-flex;
    text-decoration: none;
  }
  
  .btn-close:hover {
    border: none;
    background: none;
    display: inline-flex;
    text-decoration: none;
  }
  
  .btn-play-white {
    color: #fff;
    border: none;
  }
  
  .btn-play-white:focus, .btn-play-white:active, .btn-play-white:visited {
    border: none;
    color: #fff;
  }
  
  .btn-play-white:hover {
    border: none;
    color: #fff;
  }

  .alert button.btn.close {
    position: absolute;
    top: 0;
    right: 0;
  }
  
  .alert.alert-info {
    font-family: 'Inter', sans-serif;
    color: #fff;
    text-align: center;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    border-radius: 0;
    background-color: #4f5d75;
    border: 1px solid #4f5d75;
    color: #fff;
    text-align: center;
    font-weight: 300;
    margin: 0 auto;
  }
  
  @media (min-width: 1200px) {
    .alert.alert-info {
      font-size: 28px;
      line-height: 36px;
    }
  }
  
  @media (min-width: 768px) {
    .alert.alert-info {
      font-size: 20px;
      line-height: 26px;
    }
  }
  
  header.header-home {
    background-color: #1D252C;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

  }
  
  @media (min-width: 768px) {
    header.header-home {
      min-height: 400px;
    }
  }
  
  @media (min-width: 1200px) {
    header.header-home {
      height: 602px;
    }
  }
  
  .header-home .header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.25);
    z-index: 9;
  }
  
  .header-home .header-content-box {
    padding: 15px;
    position: relative;
    z-index: 10;
  }
  
  .header-home .header-media-box {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 8;
  }
  
  .header-home .header-home-title {
    font-family: 'Inter', sans-serif;
    color: #fff;
    text-align: center;
    font-weight: 800;
    font-size: 32px;
    line-height: 36px;
  }
  
  @media (min-width: 768px) {
    .header-home .header-home-title {
      font-size: 42px;
      line-height: 46px;
    }
  }
  
  @media (min-width: 1200px) {
    .header-home .header-home-title {
      font-size: 52px;
      line-height: 56px;
    }
  }
  
  @media (min-width: 1440px) {
    .header-home .header-home-title {
      font-size: 72px;
      line-height: 76px;
    }
  }
  
  .header-home-title .header-home-subtitle {
    color: #fff;
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
  }
  
  @media (min-width: 768px) {
    .header-home-title .header-home-subtitle {
      font-size: 18px;
      line-height: 28px;
    }
  }
  
  @media (min-width: 1200px) {
    .header-home-title .header-home-subtitle {
      font-size: 24px;
      line-height: 32px;
    }
  }
  
  @media (min-width: 1440px) {
    .header-home-title .header-home-subtitle {
      font-size: 28px;
      line-height: 36px;
    }
  }


  
  header.header-trips {
    background-color: #1D252C;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

  }
  
  @media (min-width: 768px) {
    header.header-trips {
      min-height: 400px;
    }
  }
  
  @media (min-width: 1200px) {
    header.header-trips {
      height: 602px;
    }
  }
  
  .header-trips .header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.25);
    z-index: 9;
  }
  
  .header-trips .header-content-box {
    padding: 15px;
    position: relative;
    z-index: 10;
  }
  
  .header-trips .header-media-box {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 8;
  }
  
  .header-trips .header-trips-title {
    font-family: 'Inter', sans-serif;
    color: #fff;
    text-align: center;
    font-weight: 800;
    font-size: 32px;
    line-height: 36px;
  }
  
  @media (min-width: 768px) {
    .header-trips .header-trips-title {
      font-size: 42px;
      line-height: 46px;
    }
  }
  
  @media (min-width: 1200px) {
    .header-trips .header-trips-title {
      font-size: 52px;
      line-height: 56px;
    }
  }
  
  @media (min-width: 1440px) {
    .header-trips .header-trips-title {
      font-size: 72px;
      line-height: 76px;
    }
  }
  
  .header-trips-title .header-trips-subtitle {
    color: #fff;
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
  }
  
  @media (min-width: 768px) {
    .header-trips-title .header-trips-subtitle {
      font-size: 18px;
      line-height: 28px;
    }
  }
  
  @media (min-width: 1200px) {
    .header-trips-title .header-trips-subtitle {
      font-size: 24px;
      line-height: 32px;
    }
  }
  
  @media (min-width: 1440px) {
    .header-trips-title .header-trips-subtitle {
      font-size: 28px;
      line-height: 36px;
    }
  }
  
  
  header.header-about {
    height: 300px;
    display: flex;
    justify-content: start;
    align-items: center;
    position: relative;
  }
  
  @media (min-width: 768px) {
    header.header-about {
      height: 400px;
    }
  }
  
  @media (min-width: 1200px) {
    header.header-about {
      height: 602px;
    }
  }
  
  .header-about .header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.25);
    z-index: 9;
  }
  
  .header-about .header-content-box {
    padding: 15px;
    position: relative;
    z-index: 10;
  }
  
  @media (min-width: 576px) {
    .header-about .header-content-box {
      padding: 15px 40px;
      position: relative;
      z-index: 10;
    }
  }
  
  @media (min-width: 768px) {
    .header-about .header-content-box {
      padding: 0 40px;
    }
  }
  
  @media (min-width: 992px) {
    .header-about .header-content-box {
      padding: 0 50px;
    }
  }
  
  @media (min-width: 1200px) {
    .header-about .header-content-box {
      padding: 0 100px;
    }
  }
  
  @media (min-width: 1440px) {
    .header-about .header-content-box {
      padding: 0 20px;
    }
  }
  
  .header-about .header-media-box {
    background-color: #f4f4f4;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 8;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%, center center;
  }
  
  .header-about .header-about-title {
    font-family: 'Inter', sans-serif;
    color: #fff;
    text-align: left;
    font-weight: 800;
    font-size: 32px;
    line-height: 36px;
  }
  
  @media (min-width: 768px) {
    .header-about .header-about-title {
      font-size: 42px;
      line-height: 46px;
    }
  }
  
  @media (min-width: 1200px) {
    .header-about .header-about-title {
      font-size: 52px;
      line-height: 56px;
    }
  }
  
  @media (min-width: 1440px) {
    .header-about .header-about-title {
      font-size: 72px;
      line-height: 76px;
    }
  }
  
  .header-about-title .header-about-subtitle {
    color: #fff;
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 20px;
  }
  
  @media (min-width: 768px) {
    .header-about-title .header-about-subtitle {
      font-size: 18px;
      line-height: 28px;
    }
  }
  
  @media (min-width: 1200px) {
    .header-about-title .header-about-subtitle {
      font-size: 24px;
      line-height: 32px;
    }
  }
  
  @media (min-width: 1440px) {
    .header-about-title .header-about-subtitle {
      font-size: 28px;
      line-height: 36px;
    }
  }
  
  header.header-contact {
    display: block;
    position: relative;
  }
  
  @media (min-width: 1200px) {
    header.header-contact {
      padding-left: 95px;
      padding-right: 95px;
    }
  }
  
  .header-contact .header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.25);
    z-index: 9;
  }
  
  .header-contact .header-content-box {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 30px;
    padding-left: 30px;
  }
  
  @media (min-width: 768px) {
    .header-contact .header-content-box {
      padding-top: 30px;
      padding-bottom: 30px;
      padding-right: 50px;
      padding-left: 50px;
    }
  }
  
  @media (min-width: 992px) {
    .header-contact .header-content-box {
      padding-top: 30px;
      padding-left: 50px;
      padding-right: 50px;
      padding-bottom: 30px;
    }
  }
  
  @media (min-width: 1200px) {
    .header-contact .header-content-box {
      padding-top: 50px;
      padding-bottom: 50px;
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  
  @media (min-width: 1440px) {
    .header-contact .header-content-box {
      padding-top: 70px;
      padding-bottom: 70px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  
  .header-contact .header-media-box {
    background-color: #f4f4f4;
    display: block;
    width: 100%;
    height: 200px;
  }
  
  @media (min-width: 576px) {
    .header-contact .header-media-box {
      height: 280px;
    }
  }
  
  @media (min-width: 768px) {
    .header-contact .header-media-box {
      height: 360px;
    }
  }
  
  @media (min-width: 992px) {
    .header-contact .header-media-box {
      height: 420px;
    }
  }
  
  @media (min-width: 1200px) {
    .header-contact .header-media-box {
      height: 560px;
    }
  }
  
  @media (min-width: 1440px) {
    .header-contact .header-media-box {
      height: 650px;
    }
  }
  
  .header-contact .header-contact-title {
    font-family: 'Inter', sans-serif;
    color: #ef8354;
    text-align: left;
    font-weight: 800;
    font-size: 19px;
    line-height: 26px;
  }
  
  @media (min-width: 768px) {
    .header-contact .header-contact-title {
      font-size: 21px;
      line-height: 28px;
    }
  }
  
  @media (min-width: 1200px) {
    .header-contact .header-contact-title {
      font-size: 26px;
      line-height: 34px;
    }
  }
  
  @media (min-width: 1440px) {
    .header-contact .header-contact-title {
      font-size: 28px;
      line-height: 36px;
    }
  }
  
  .header-contact-title .header-contact-subtitle {
    color: #1D252C;
    display: block;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
  }
  
  @media (min-width: 768px) {
    .header-contact-title .header-contact-subtitle {
      font-size: 15px;
      line-height: 22px;
    }
  }
  
  @media (min-width: 1200px) {
    .header-contact-title .header-contact-subtitle {
      font-size: 17px;
      line-height: 25px;
    }
  }
  
  @media (min-width: 1440px) {
    .header-contact-title .header-contact-subtitle {
      font-size: 19px;
      line-height: 27px;
    }
  }
  
  .header-page .header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.25);
    z-index: 9;
  }
  
  .header-page .header-content-box {
    padding: 15px;
    position: relative;
    z-index: 10;
  }
  
  .header-page .header-media-box {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 8;
  }
  
  .header-page .header-page-title {
    font-family: 'Inter', sans-serif;
    color: #1D252C;
    text-align: center;
    font-weight: 800;
    font-size: 32px;
    line-height: 36px;
  }
  
  @media (min-width: 768px) {
    .header-page .header-page-title {
      font-size: 42px;
      line-height: 46px;
    }
  }
  
  @media (min-width: 1200px) {
    .header-page .header-page-title {
      font-size: 52px;
      line-height: 56px;
    }
  }
  
  @media (min-width: 1440px) {
    .header-page .header-page-title {
      font-size: 72px;
      line-height: 76px;
    }
  }
  
  .header-page-title .header-page-subtitle {
    color: #1d252c;
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-top: 5px;
  }
  
  @media (min-width: 768px) {
    .header-page-title .header-page-subtitle {
      font-size: 18px;
      line-height: 28px;
    }
  }
  
  @media (min-width: 1200px) {
    .header-page-title .header-page-subtitle {
      font-size: 24px;
      line-height: 32px;
      margin-top: 15px;
    }
  }
  
  @media (min-width: 1440px) {
    .header-page-title .header-page-subtitle {
      font-size: 28px;
      line-height: 36px;
    }
  }
  
  header.header-post {
    background-color: #F5DDD8;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  @media (min-width: 768px) {
    header.header-post {
      height: 400px;
    }
  }
  
  @media (min-width: 1200px) {
    header.header-post {
      height: 602px;
    }
  }
  
  .header-post .header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.25);
    z-index: 9;
  }
  
  .header-post .header-content-box {
    padding: 15px;
    position: relative;
    z-index: 10;
  }
  
  .header-post .header-media-box {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 8;
  }
  
  .header-post .header-post-title {
    font-family: 'Inter', sans-serif;
    color: #1D252C;
    text-align: center;
    font-weight: 800;
    font-size: 32px;
    line-height: 36px;
  }
  
  @media (min-width: 768px) {
    .header-post .header-post-title {
      font-size: 42px;
      line-height: 46px;
    }
  }
  
  @media (min-width: 1200px) {
    .header-post .header-post-title {
      font-size: 52px;
      line-height: 56px;
    }
  }
  
  @media (min-width: 1440px) {
    .header-post .header-post-title {
      font-size: 72px;
      line-height: 76px;
    }
  }
  
  .header-post .header-post-subtitle {
    color: #1d252c;
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
  }
  
  @media (min-width: 768px) {
    .header-post .header-post-subtitle {
      font-size: 18px;
      line-height: 28px;
    }
  }
  
  @media (min-width: 1200px) {
    .header-post .header-post-subtitle {
      font-size: 24px;
      line-height: 32px;
    }
  }
  
  @media (min-width: 1440px) {
    .header-post .header-post-subtitle {
      font-size: 28px;
      line-height: 36px;
    }
  }
  
  .section-concept, .section-adventure {
    padding: 30px 5px;
  }
  
  @media (min-width: 768px) {
    .section-concept, .section-adventure {
      padding: 40px;
    }
  }
  
  @media (min-width: 768px) {
    .section-adventure .container:first-child {
      margin-bottom: 30px;
    }
  }
  
  @media (min-width: 1200px) {
    .section-concept, .section-adventure {
      padding: 95px;
    }
  }
  
  .concept-content-box {
    text-align: center;
    padding-bottom: 15px;
  }
  
  @media (min-width: 768px) {
    .concept-content-box {
      padding-bottom: 15px;
      width: auto;
    }
  }
  
  .concept-content-title {
    color: #b8b8ff;
    font-size: 32px;
    line-height: 36px;
    font-weight: 800;
  }
  
  @media (min-width: 768px) {
    .concept-content-title {
      font-size: 42px;
      line-height: 46px;
      font-weight: 800;
    }
  }
  
  @media (min-width: 1440px) {
    .concept-content-title {
      font-size: 72px;
      line-height: 76px;
      font-weight: 800;
    }
  }
  
  .concept-content-summary {
    color: #1D252C;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    width: 75%;
  }
  
  @media (min-width: 768px) {
    .concept-content-summary {
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;
      width: 75%;
    }
  }
  
  .concept-media-box {
    width: 300px;
    height: 200px;
    margin-bottom: 30px;
    margin-top: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
  }
  
  @media (min-width: 768px) {
    .concept-media-box {
      margin-top: 0;
      margin-bottom: 0;
      width: 100%;
      height: 300px;
    }
  }
  
  @media (min-width: 1200px) {
    .concept-media-box {
      width: 100%;
      max-width: 653px;
      height: 478px;
    }
  }
  
  .adventure-content-box {
    width: 300px;
  }
  
  @media (min-width: 768px) {
    .adventure-content-box {
      width: auto;
    }
  }
  
  .adventure-content-title {
    color: #ffffff;
    font-size: 26px;
    line-height: 34px;
    font-weight: 600;
  }
  
  @media (min-width: 768px) {
    .adventure-content-title {
      font-size: 21px;
      line-height: 28px;
    }
  }
  
  @media (min-width: 1200px) {
    .adventure-content-title {
      font-size: 36px;
      line-height: 44px;
    }
  }
  
  .adventure-content-summary {
    color: #ffffff;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    width: 75%;
  }
  
  @media (min-width: 768px) {
    .adventure-content-summary {
      font-size: 15px;
      line-height: 23px;
      font-weight: 400;
      width: 75%;
    }
  }
  
  .adventure-image-box {
    border: 2px solid #F6C4D1;
    border-radius: 15px;
    max-width: 300px;
    height: 465px;
    margin-bottom: 10px;
  }
  
  @media (min-width: 768px) {
    .adventure-image-box {
      width: 100%;
      height: 300px;
    }
  }
  
  @media (min-width: 992px) {
    .adventure-image-box {
      width: 100%;
      height: 350px;
    }
  }
  
  @media (min-width: 1200px) {
    .adventure-image-box {
      width: 100%;
      max-width: 350px;
      height: 465px;
    }
  }
  
  .section-concept.mint-bg {
    background-color: #b8b8ff;
  }
  
  .section-concept.mint-bg .concept-content-title {
    color: #ffffff;
    text-align: center;
  }
  
  @media (min-width: 768px) {
    .section-concept.mint-bg .concept-content-title {
      text-align: left;
    }
  }
  
  .section-concept.mint-bg .concept-content-summary {
    color: #fff;
    text-align: center;
    width: 100%;
  }
  
  @media (min-width: 768px) {
    .section-concept.mint-bg .concept-content-summary {
      text-align: left;
      width: 75%;
    }
  }
  
  .section-concept.teal-bg, .section-adventure.teal-bg {
    background-color: #9381ff;
  }
  
  .section-concept.teal-bg .concept-content-title, .section-adventure.teal-bg .concept-content-title {
    color: #ffffff;
    text-align: center;
  }
  
  .section-concept.teal-bg .concept-content-summary {
    color: #fff;
    text-align: center;
    width: 100%;
  }
  
  .section-adventure.teal-bg .concept-content-summary {
    color: #ffffff;
  }
  
  .section-concept.revival-bg {
    background-color: #ff0054;
  }
  
  .section-concept.revival-bg .concept-content-title {
    color: #ffffff;
    text-align: center;
  }
  
  @media (min-width: 768px) {
    .section-concept.revival-bg .concept-content-title {
      text-align: left;
    }
  }
  
  .section-concept.revival-bg .concept-content-summary {
    color: #fff;
    text-align: center;
    width: 100%;
  }
  
  @media (min-width: 768px) {
    .section-concept.revival-bg .concept-content-summary {
      text-align: left;
      width: 75%;
    }
  }
  
  .section-concept.growth-bg {
    background-color: #2ec4b6;
  }
  
  .section-concept.growth-bg .concept-content-title {
    color: #ffffff;
    text-align: center;
  }
  
  .section-concept.growth-bg .concept-content-summary {
    color: #fff;
    text-align: center;
    width: 100%;
  }
  
  .section-concept.community-bg {
    background-color: #F5DDD8;
  }
  
  .section-concept.community-bg .concept-content-title {
    color: #ff8600;
    text-align: center;
  }
  
  .section-concept.community-bg .concept-content-summary {
    color: #ff8600;
    text-align: center;
    width: 100%;
  }
  
  .section-concept.invert-order .col-md-6:first-child {
    order: 1;
  }
  
  .section-concept.invert-order .col-xl-5:first-child {
    order: 1;
  }
  
  @media (min-width: 992px) {
    .section-concept.invert-order .concept-content-box {
      padding-left: 70px;
    }
  }
  
  .section-full-image {
    height: 360px;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
  }
  
  @media (min-width: 1200px) {
    .section-full-image {
      height: 650px;
      background-color: #ffffff;
    }
  }
  
  .section-map {
    height: 360px;
    overflow: hidden;
    background-color: #f4f4f4;
  }
  
  @media (min-width: 1200px) {
    .section-map {
      height: 680px;
      background-color: #DBDBDB;
    }
  }
  
  .map-box {
    position: relative;
    height: 0;
    padding-top: 56.25%;
  }
  
  .map-box iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .logo-box img {
    max-height: 40px;
  }
  
  @media (min-width: 992px) {
    .logo-box img {
      max-height: none;
    }
  }
  
  .logo-box {
    padding: 5px;
  }
  
  a.logo-link, a.logo-link:hover {
    text-decoration: none;
    display: inline-block;
  }
  
  .footer-logo {
    max-width: 180px;
  }

  .modal-backdrop.show {
    opacity: 1;
  }

  .modal-header.modal-header-close {
    position: absolute;
    right: 8px;
    top: -8px;
    z-index: 1;
    border: none;
  }

  @media (min-width: 768px) {
    .modal-header.modal-header-close {
      right: 20px;
      top: -5px;
    }
  }

  .place-modal .modal-content {
    height: 80vh;
  }
  
  .modal-body.video-box {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: 0 auto 0;
    padding: 0;
  }
  
  .video-box .video {
    position: relative;
    height: 0;
    padding-top: 56.25%;
    margin-left: 0;
    margin-right: 0;
  }
  
  .video video {
    width: 100%;
    height: auto;
  }
  
  .video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    margin-right: auto;
    text-decoration: none;
    white-space: nowrap;
  }
  
  
/*** Videos ***/

.myvideo-box {
	width:  100%;
	height: auto;
	margin: auto;
    border: 1px solid rgba(255,255,255,0.15);
    border-radius: 7px;
	overflow: hidden;
}
.myvideo {
	position:  relative;
	height:  0;
	padding-top:  56.25%;
}
.myvideo iframe {
	position:  absolute;
	top:  0;
	left:  0;
	width:  100%;
	height:  100%;
}

.box-content__title {
  font-weight: 600;
  font-size: 1.2rem;
}
.box-content__category {
  font-weight: 400;
  font-size: .9rem;
}
.box-content__creator {
  font-weight: 400;
  font-size: .9rem;
}
